.col input {
  /* position: relative; */
  /* margin-top: 20px; */
  margin-right: 50px;
}
.col label {
  /* margin-left: 30px; */
  margin-top: -20px;
  /* top: -10px; */
}
label {
  font-weight: 500;
}
.col {
  margin-top: 20px;
  right: 500px;
}
.col div {
  /* position: relative; */
  margin-left: 200px;
}
.flex-column {
  background-color: white;
}
