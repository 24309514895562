/* 
@media screen and (min-width:768px){
    .foot{
        position: absolute;
        background-color: #068BFF;
        bottom: 0%;
        width: 100%;
        height: 30%;
    }
    .logo{
        width: 20%;
        position: absolute;
        left: 0%;
        height: 30%px;
        top: 10%;
    }
    .traikefa{
        position: absolute;
        width: 180px;
        height: 100px;
    }
    .facebook{
        position: absolute;
        width: 50px;
        height: 50px;
    }
    .instagram{
        position: absolute;
        width: 50px;
        height: 50px;
    }
    .linkedin{
        position: absolute;
        width: 50px;
        height:50px;
    }
    .info{
        position: absolute;
        width: 25%;
        left: 40%;
        top: 5%;
        color: white;
        font-size: large;
    }
    .contact{
        position: absolute;
        width: 20%;
        left: 75%;
        top: 5%;
        color: white;
        font-size: large;
    }
    .copy{
        position: absolute;
        bottom: 0%;
        width: 100%;
        background-color: #890103;
        text-align: center;
        color: white;
        font: 20px;
    }
    .p1{
        position: absolute;
        top: 50%;
        left: 50%;
    }
    .p2{
        position: absolute;
        top: 140px;
        left: 40%;
    }
    .p3{
        position: absolute;
        left: 65%;
        top: 140px;
    }
    .p4{
        position:absolute;
        left: 90%;
        top: 140px;
    }
    .mail{
        position: absolute;
        top:100%;
        width: 100%;
    }
    .mail input{
        height:40px;
        border-radius: 20px;
        background-color: transparent;
        border-color: white;
        outline: none;
    }
    .em::placeholder {
        color: white; 
      }
    .but button{
        position: absolute;
        background-color: #890103;
        border-radius: 5%;
        width: 40%;
        height: 30%;
        left: 60%;
        top: 100%;
        color: white;
    }
    
}
@media screen and(max-width: 767px) {
    
} */
h6 {
  display: flex;
  align-items: center;
  margin-left: 5px;
}
