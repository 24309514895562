.opportunitecontainer {
  padding: 40px 20px 20px 20px;
  width: 99%;
  margin: auto;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  flex-wrap: wrap;
}

.card_item {
  padding: 15px 30px;
  width: 24%;
  text-align: center;
}

.opportunitecontainer .card_item:hover {
  transform: scale(1.02);
  transition: transform 0.3s;
  cursor: pointer;
}

.opportunitecontainer .card_inner {
  background: rgba(253, 253, 253, 0.808);
  border-radius: 10px;
  padding: 35px 20px;
  min-width: 225px;
  height: 255px;
  width: 100%;
  box-shadow: 1px 1px 1px 1px rgba(95, 95, 95, 0.24);
}

.opportunitecontainer .card_item img {
  width: 120px;
  height: 100px;
  margin-bottom: 10px;
  border-radius: 50%;
  align-items: center;
  transition: transform 0.2s;
}

.opportunitecontainer .card_item .userName {
  color: #203150;
  font-weight: 600;
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.opportunitecontainer .card_item .userUrl {
  font-size: 12px;
  font-weight: 100;
  margin: 5px 0 10px;
  word-wrap: break-word;
  color: #888b9e;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.opportunitecontainer .card_item .gitDetail {
  font-size: 14px;
  line-height: 24px;
  color: #7b8ca0;
  display: flex;
  flex-direction: column;
}

.detail-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.detail-box span {
  margin: 2px;
  padding: 2px 10px;
  /* border: 2px solid #c7e6fa; */
  background-color: #f0ebfa;
  border-radius: 50px;
  font-size: 10px;
  font-weight: 600;
  color: #788697;
}

.seeMore {
  margin: 10px auto;
  width: 100px;
  height: 35px;
  background: hsl(245, 44%, 65%);
  color: #fff;
  border: none;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .opportunitecontainer .card_item {
    width: 33%;
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .opportunitecontainer .card_item {
    width: 50%;
  }
  .wrapper .header {
    font-size: 16px;
    height: 50px;
  }
}

@media screen and (max-width: 568px) {
  .opportunitecontainer .card_item {
    width: 80%;
    margin: auto;
  }
}

@media screen and (max-width: 320px) {
  .opportunitecontainer .card_item {
    width: 100%;
  }
}

.filtre {
  justify-content: center;
}
.event :hover {
  background: #068bff;
}
