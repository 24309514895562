.modification {
  background-color: #068cff;
}
.contenu {
  width: 80%;
  height: auto;
  margin-top: 20%;
  height: 100%;
}
.sary {
  position: absolute;
  width: 20%;
  height: 50%;
  left: 10%;
  top: 25%;
}
.formu {
  border-radius: 40px 0 0 40px;
  padding: 10%;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.inputbox {
  position: relative;
  width: 100%;
  border-bottom: 2px solid #000000;
  margin-bottom: 13%;
}

.inputbox input {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  font-size: 1em;
  color: #000000;
}
.titre {
  position: absolute;
  text-align: center;
  bottom: 110%;
  right: 0%;
  width: 100%;
}

.titre::after {
  content: "";
  position: absolute;
  top: 140%;
  left: 38%;
  width: 25%;
  border-bottom: 3px solid #1b1b1b;
}
.section {
  position: absolute;
  top: 17%;
  left: 54%;
  width: 30%;
}
.modif {
  position: absolute;
  top: 100%;
  width: 60%;
  height: 12%;
  left: 23%;
  border-radius: 40px;
  background-color: #068cff;
  color: white;
  border-color: transparent;
  font-size: 20px;
}
.ppp {
  position: absolute;
  font-size: 18px;
  top: 130%;
  left: 14%;
  font-weight: bold;
}
.ppp a {
  text-decoration: none;
}
