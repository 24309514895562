.recuperation {
  background-color: #068cff;
}
.content {
  width: 80%;
  height: auto;
}
.formu {
  border-radius: 40px 0 0 40px;
  flex-direction: column;
}
.inputbox {
  position: relative;
  width: 90%;
  border-bottom: 2px solid #000000;
}

.inputbox input {
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  font-size: 1em;
  color: #000000;
}
.souligner {
  position: absolute;
  text-align: center;
  bottom: 100%;
  left: 5%;
  width: 100%;
}

.souligner::after {
  content: "";
  position: absolute;
  top: 140%;
  left: 38%;
  width: 25%;
  border-bottom: 3px solid #1b1c1d;
}
.section {
  position: absolute;
  top: 20%;
  left: 70%;
}
.recup {
  position: absolute;
  top: 100%;
  width: 50%;
  height: 18%;
  left: 25%;
  border-radius: 40px;
  background-color: #068cff;
  border-color: transparent;
  color: white;
  font-size: 100%;
}
.paragraphe {
  position: absolute;
  font-size: 18px;
  top: 140%;
  left: 20%;
  font-weight: bold;
}
.paragraphe a {
  text-decoration: none;
}
