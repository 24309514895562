.flex-item {
  width: 50%;
  justify-content: space-around;
}
.row .flex-item1 {
  margin-top: 15px;
}
.bold {
  justify-content: center;
}
.form-control {
  justify-content: space-between;
}
.textfield {
  width: 100%;
  height: 500px;
}
.btn {
  border-radius: 1%;
}
