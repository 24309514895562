/* ... (Previous CSS code) ... */
.Flex-container {
                 display: flex;
                 justify-content: space-between;
                 align-items: start-flex;
                }
  
  .Flex-child {
                display: flex;
                flex-direction: column; 
              }
  
  .left-section {
                 padding: 20px;
                 display: flex;
                 width: 1200px;
                 height: 900px;  
               }
  
  .right-section {
                  padding: 30px;
                  margin-left: auto;
                 }
  
  .row {
         display: flex;
         align-items: start-flex;
         flex-direction: row; 
       }
  #echo.btn {
           marging-left: 20 px;
            }
p {
    text-align: left;   
  }
h5{
    text-align: center;
  }
.card-body{
           margin: 20 px;
          }
.footer {
          margin: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between; /* Add this line to align the elements between the left and right */
}   

.profile {
          display: flex; /* Use 'inline-block' for older browsers */
          align-items: left;  
        }

.header {
          margin: 10px;
          display: flex;
          align-items:center;
          justify-content:space-between;
          margin-bottom: 0;
        }



p{
  margin: 10px;
  padding: 0px;
  }

 .card:hover {
              visibility: visible;
              opacity: 1;
              margin-top: -10px;
              transition-delay: 0.3s;
              box-shadow: rgba(0, 0, 0, 0.20) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}


.container#1 {
              display: flex;
              flex-wrap: wrap;
              height: 100vh;
              justify-content: flex-start;
              padding:10 px;
              align-items:flex-start;
              margin: 0 auto;
             }

.rating {
          display: flex;
          width: 70px;
          justify-content: center;
          overflow: hidden;
          flex-direction: row-reverse;
          height: 130px;
          position: relative;
        }

.rating-0 {
            filter: grayscale(100%);
          }

.rating > input {
                 display: none;
                }

.rating > label {
                  cursor: pointer;
                  width: 40px;
                  height: 40px;
                  margin-top: auto;
                  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23e3e3e3' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: 70%;
                  transition: .3s;
                }

.rating > input:checked ~ label,
.rating > input:checked ~ label ~ label {
                                          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23fcd93a' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
                                        }


.rating > input:not(:checked) ~ label:hover,
.rating > input:not(:checked) ~ label:hover ~ label {
                                                     background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23d8b11e' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
                                                    }

.emoji-wrapper {
                  width: 100%;
                  text-align: center;
                  height: 100px;
                  overflow: hidden;
                  position: absolute;
                  top: 0;
                  left: 0;
               }

.emoji-wrapper:before,
.emoji-wrapper:after{
                      content: "";
                      height: 15px;
                      width: 100px;
                      position: absolute;
                      left: 0;
                      z-index: 1;
}

.emoji-wrapper:before {
                        top: 0;
                        background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 35%,rgba(255,255,255,0) 100%);
                      }

.emoji-wrapper:after {
                      bottom: 0;
                      background: linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 35%,rgba(255,255,255,0) 100%);
                    }

.emoji {
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: 0.3s;
       }

.emoji > svg {
              margin: 15px; 
              width: 70px;
              height: 70%;
              flex-shrink: 0;
             }

#rating-1-right:checked ~ .emoji-wrapper > .emoji { transform: translateY(-100px); }
#rating-2-right:checked ~ .emoji-wrapper > .emoji { transform: translateY(-200px); }
#rating-3-right:checked ~ .emoji-wrapper > .emoji { transform: translateY(-300px); }
#rating-4-right:checked ~ .emoji-wrapper > .emoji { transform: translateY(-400px); }
#rating-5-right:checked ~ .emoji-wrapper > .emoji { transform: translateY(-500px); }

#rating-1-left:checked ~ .emoji-wrapper > .emoji { transform: translateY(-100px); }
#rating-2-left:checked ~ .emoji-wrapper > .emoji { transform: translateY(-200px); }
#rating-3-left:checked ~ .emoji-wrapper > .emoji { transform: translateY(-300px); }
#rating-4-left:checked ~ .emoji-wrapper > .emoji { transform: translateY(-400px); }
#rating-5-left:checked ~ .emoji-wrapper > .emoji { transform: translateY(-500px); }

.feedback {
            max-width: 260px;
            width: 50%;
            padding:2px ;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            padding:10 px;
            align-items:flex-end;
            margin:  0 auto;
          }

#div {
       width: 20 px;
       height : 20 px;
     }



/* Add media queries for responsiveness */

/* ... (Previous CSS code) ... */

/* Add media queries for responsiveness */
@media screen and (max-width: 768px) {
  .Flex-container {
    flex-direction: column;
    align-items: center;
  }

  .left-section {
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
  }

  .right-section {
    margin-left: 0;
    margin-top: 20px;
  }

  .card {
    width: 90%;
    margin: 0 auto;
  }

  .rating-container {
    justify-content: center;
  }

  .rating > input,
  .rating > label {
    width: 30px;
    height: 30px;
    background-size: 60%;
  }

  .Flex-child.right-section .card {
    padding: 20px;
  }

  .feedback {
    width: 100%; /* Set full width for smaller screens */
  }

  .emoji-wrapper:before,
  .emoji-wrapper:after {
    height: 10px;
  }

  .emoji > svg {
    width: 50px;
    height: 50%;
  }
}

