.container_inscription {
  margin-top: 30px;
}

.input_radio {
  display: flex;
  gap: 5%;
}

/* CSS */
.input_radio_group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px; /* Arrange items vertically */
}

.input_radio_group label {
  /* Your label styling */
  margin-top: -20px;
  margin-left: 15px;
}

.input_radio_group input[type="radio"] {
  align-self: flex-start;
  margin-right: -25px;
  margin-top: 10px;
}

.input_radio .label_sexe {
  font-size: 18px;
}

.btn_inscription {
  color: #fff;
  font-size: 18px;
  background: #068cff;
  height: 40px;
  border: 0.5px solid #ccc;
  border-radius: 30px;
  display: block;
  position: absolute;
  bottom: 12%;
  /* left: 63%; */
  transform: translate(-50%, -50%);
}

.btn_inscription:hover {
  background: #068fff;
}

.lien_connecte {
  margin-top: 10px;
  /* margin-left: 50px; */
  font-size: 18px;
  transition: 0.4s;
  cursor: pointer;
  position: absolute;
  bottom: 8%;
  left: 42%;
  transform: translate(-50%, -50%);
}

.lien_connecte::after {
  background: #068cff;
}

.lien_connecte:hover {
  color: #068cff;
  text-decoration: underline;
}

.container_input {
  display: flex;
}

.input {
  width: 85%;
  height: 40px;
  padding-left: 10px;
  font-size: 18px;
}

.div_input {
  margin-bottom: 25px;
}

.p_errors {
  margin: 0;
  padding: 0;
  margin-top: -3px;
  position: absolute;
  color: red;
}

.btn_pagination_inscription div {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  gap: 40px;
}

.btn_pagination_inscription button {
  border-radius: 7px;
  width: 150px;
  height: 40px;
}

.btn_pagination_inscription button:hover {
  background: #54afff;
}

.information_titre_identite {
  width: 93%;
  background: #068cff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding: 5px;
  margin-bottom: 10px;
}
.content {
  justify-content: center;
}
