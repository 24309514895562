.p{
    position: absolute;
    font-size: 85%;
    color: solid;
    top: 10%;
    margin-left: 1%;
   }
   .H1{
    font-size: 10%;
    color: blue;
    position: absolute;
    top: 2%;
   }
  
    .form{
      margin-top: 7%;
      margin-bottom: 10%;
     
    }
    
    .btn button{

      width:100%;
      margin-top: -8%;
      right: 50%;
      border-color: blue;
      color: blue;
      outline: none;
      margin-left: 50%;
    }
  
    .btn-ajouter{
      width: 20%;
      margin-left: 70%;
      margin-top: -5.5%;
      border-radius: 5%;
    }
  
    /* .error-message{
      position: relative;
      font-size: 30px;
      margin-top: -3%;
      color: red;
    } */
    .inputboxe {
      position: absolute;
      border-bottom : 2px solid #e0d9d9;
     
  }
  .inputboxe input{
     
      border: none;
      border-bottom: 0px solid;
      outline: none; /* Remove the default focus outline */
      padding: 5px 0; /* Adjust padding as needed */
      background-color: #978d8d;
      transition: border-bottom 0.3s ease;
      flex: 1; /* Take up the remaining space */
      padding: 5px; /* Adjust the input padding as needed */
      font-size: 16px; /* Adjust the input font size as needed */
    
  }
  .inputboxe {
      display: flex; /* Align label and input horizontally */
      align-items: center; /* Center items vertically within the flex container */
      margin-bottom: 10px; /* Add some space between input boxes */
    }
    
    .inputboxe label {
      flex: 0 0 150px; /* Set the width of the label (adjust as needed) */
      margin-right: 10px; /* Add some space between label and input */
    }
    
    .inputboxe {
      position: relative;
      margin-bottom: 10px;
    }
   
    .select-container {
      position: relative;
    }
    
    .select-container label {
      position: absolute;
      top: -15px; /* Adjust as needed to position the label */
      left: 10px;
      background-color: #fff;
      padding: 0 5px;
      font-size: 14px;
      color: #777;
      
    }
    
    .select-container select {
      padding: 5px;
      font-size: 15px;
      border: 1px solid #ccc; /* Normal border color */
      border-radius: 5px;
      width: 100%; /* Make the select element take the full width */
      appearance: none; /* Remove default styles on some browsers */
      border: none;
      border-bottom : 2px solid #e0d9d9;
    }
    
    /* Style the label when the select is focused or has a value */
    .select-container select:focus + label,
    .select-container select:not(:placeholder-shown) + label {
      top: -28px; /* Adjust as needed for the focused or value state */
      left: 5px;
      font-size: 12px;
      background-color: #f9f9f9;
      padding: 0 5px;
      color: #555;
    }
    .left{
      width: 98%;
    }