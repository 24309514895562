.contenera{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    align-items: center;
    justify-content: space-around;
}
.sous_container{
    height: auto;
    box-shadow: 1px 0px 6px 3px #9c9c9c;
    border-radius: 10px;
    margin: 10px;
    padding: 15px;
}

.entete{
    width:100%;
    height:auto;
    display:flex;
    align-items:center;
    justify-content: space;
}

.entete .date{
    display: flex;
    align-items:center;
    justify-content: center;
    border : 1px solid #ccc;
    border-radius: 100%;
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    background: #068BFF;
}

.entete_droit{
    width:100%;
}

.entete .date span{
    display: flex;
    color: #F5E7E7;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.nom_auteur{
    display: flex;
    align-items:center;
    justify-content: center;
}

.categorie_offre{
    display: flex;
    align-items:center;
    justify-content: center;
}

.footer{
    width:100%;
    display: flex;
    align-items:center;
    justify-content:space-around;
}

.voir_plus{
    background:#7B8085;
    border: 1px solid #7B8085;
    border-radius: 30px;
    color: #fff;
    width: 120px;
    height:40px;
}

.voir_plus:hover{
    background:#bdbdbd;
}

.postuler{
    background:#068BFF;
    color: #fff;
    width: 120px;
    height:40px;
    border: 1px solid #068BFF;
    border-radius: 40px;
}

.postuler:hover{
    background:#63b6ff;
}

.btn_voir_plus_evenement{
    background: #068BFF;
    color: #fff;
    padding: 9px;
    border-radius: 8px;
    border: 1px solid #ccc;
    margin-top: 30px;
    width: 200px;
    cursor: pointer;
    transition: 0.4s;
    position: relative;
    left: 50%;
    top: 90%;
    transform: translate(-50%, -50%);
}

.btn_voir_plus_evenement:hover{
    background: #83c5ff;
}