/* styles.css */
.form{
  border: 1px solid #000000;
  width: 300px;
  height: 300px;
  padding-top: 20px;
  border: none;
}
  
  .inputbox {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 1px solid #000000;

  }
  .inputbox input {
    width: 90%;
    height: 70%;
    background: transparent;
  }
  
  .i {
    align-items: center;
    justify-content: center;
    background-color: white;
  }
  
  .bi {
    cursor: pointer;
    background-color: white;
    color: #000000;
  }
  
  .inputfile {
    display: none;
  }
  
  .inputfile + label {
    cursor: pointer;
  }
  
  .text-titleText {
    background-color: #f2f2f2;
    border: none;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    color: #333;
  }
  
  .text-transparent {
    background-color: transparent;
    border: none;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    color: #333;
  }
  
  .btn-envoyer {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    margin-top: 20px;
    margin-left: 68px;
    cursor: pointer;
  }
  .carde{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    margin-top: 10%;
  }

  .error-message{
    position: relative;
    color: red;
  }