.container_pub {
  /* display: flex;
    align-items:center;
    justify-content:space-between; */
  /* margin:10px; */
  width: 100%;
  height: 100vh;
}

.text_pub {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 200%;
}

.img_pub_accueil {
  height: 100vh;
  width: 100%;
}

.sous_container_pub {
  width: 100%;
  height: 100vh;
  box-shadow: 2px 3px 5px 3px #aaa;
  display: flex;
  background: #eef;
}

.photo_pub img {
  /* height: 110px; */
}

.footer_pub {
  /* background: #068CFF; */
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 100%;
  height: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer_pub p {
  color: #fff;
  font-size: 40px;
}

.footer_pub p span {
  font-weight: bold;
}

.footer_pub .petit_text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer_pub .petit_text p {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -7px;
}

.img_valise {
  margin-left: 3px;
}

.input_footer_acceuil {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  width: 100%;
  background: #fff;
}

.input_footer_acceuil div {
  width: 50%;
  height: 60px;
  background: #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  border-right: 1px solid #aaa;
}

.input_footer_acceuil input {
  outline: none;
  border: none;
  /* background: #e9e9e9; */
  background: none;
  width: 95%;
  height: 100%;
  font-size: 18px;
}

.input_footer_acceuil img {
  width: 35px;
}

.input_footer_acceuil botton {
  height: 100%;
  width: 60px;
  background: #88c7fe;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.4s;
}

.input_footer_acceuil span:hover {
  background: #80c7fe;
}

.titre p {
  font-size: 40px;
  /* font-weight: ; */
  color: #fff;
  display: flex;
  width: 600px;
  justify-content: center;
  align-items: center;
  margin: -8px;
}

.titre p span {
  font-weight: bold;
}

.div_titre_evenement {
  display: flex;

  width: 100%;
}

.titre_evenement {
  position: relative;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  margin-top: 50px;
}

.sous_container_partenaire {
  box-shadow: 0px 0px 5px 4px #ccc;
}

.photo_partenaire img {
  width: 100%;
}

.contenu_partenaire div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contenu_partenaire .titres {
  font-weight: bold;
}

.contenu_partenaire .domaine {
  margin-top: -10px;
}
