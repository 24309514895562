.Flex-container {
                 display: flex;
                 justify-content: space-between;
                 align-items: start-flex;
                }
.Flex-child {
             display: flex;
             flex-direction: column; 
           }
.left-section {
               padding: 2rem;
               display: flex;
              
              }

.right-section {
                 padding: 3 rem;
                 margin-left: auto;  
                }

.header {
          margin: 1 rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

.card-body {
            margin: 2 rem;
           }

.profile {
          display: flex; /* Use 'inline-block' for older browsers */
          align-items: center; 
         }

 p  {
     
     margin: 0.5 rem;
     padding: 0 rem;
    }

.icon {
       display:Flex;
      }

.footer {
         margin: 10px;
         display: flex;
         align-items: center;
         justify-content: space-between; /* Add this line to align the elements between the left and right */
        }
#col{
      padding: 5px;
      margin-left: auto;
      padding-left: 10px; 
   }
   #com {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
   .textarea-container {
    flex: 1;
    margin-right: 10px; /* Optional: Add some space between the textarea and the button */
  }
  
  #com textarea {
    width: 100%;
    resize: none;
  }
  
  #com button {
    flex: 0;
    width: 120px; /* Adjust the width of the button as needed */
  }     
#in{
     margin-right: 30px;
   }

   @media (max-width: 576px) {
    .left-section, .right-section {
      padding: 1rem;
    }
  
    .card-body {
      margin: 1rem;
    }
  }
  
  /* Styles for medium screens (576px to 768px) */
  @media (min-width: 576px) and (max-width: 768px) {
    .left-section, .right-section {
      padding: 2rem;
    }
  
    .card-body {
      margin: 2rem;
    }
  }
  
  /* Styles for large screens (768px and above) */
  @media (min-width: 768px) {
    .left-section, .right-section {
      padding: 3rem;
    }
  
    .card-body {
      margin: 3rem;
    }
  }
  







