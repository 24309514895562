.flex_item {
  width: 50%;
}
.flex_row {
  height: 100%;
}
.flex_item1 {
  background-color: #068cff;
}
.flex-column {
  background-color: #068cff;
}
.flex_item1 {
  height: 100%;
  width: 50%;
}

.card_box {
  padding-top: 25%;
  padding-left: 25%;
  width: 90%;
}
